/* .result-btn{
    text-transform: none !important;
} */

.tabs {
    margin-top: 50px !important;
    margin-left: 15px !important;
}

.tab-list {
    margin-bottom: 0px !important;
}

.text-center.text-secondary {
    margin-top: 50px;
    margin-bottom: 0px;
    padding: 0px 15px !important;
}

.tab-name {
    color: #720e37 !important;
    width: 150px;
}

.MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
    background-color: #720e37 !important;
}
.MuiTabs-indicator.css-ttwr4n{
    background-color: #720e37 !important;
}

.result-tabs .css-13xfq8m-MuiTabPanel-root {
    padding: 15px;
}

.result-tabs .MuiList-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.result-tabs .MuiList-padding .MuiPaper-root.MuiPaper-elevation:first-child {
    margin-top: 0px !important;
}

.loader {
    width: 5% !important;
    margin: auto !important;
    margin-top: 250px !important;
}

.no-products-title {
    color: #720e37 !important;
    text-align: center;
    padding-top: 50px !important;
}
.css-w8dmq8{
    width: 60% !important;
    margin:auto;
}

@media screen and (max-width: 1199px) {
    .result-tabs {
        max-width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .result-tabs li.MuiListItem-root.MuiListItem-gutters p {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}