@font-face {
  font-family: 'Noto Sans SemBd';
  src: url('./fonts/NotoSans-SemiBold.woff2') format('woff2'), url('./fonts/NotoSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

hr {
  opacity: 0.4;
}

.card-step-section {
  max-width: 450px;
  margin: 0 auto;
  width: 90%;
}

.card-step-wrapper {
  padding: 40px 34px;
  height: auto !important;
  margin: 50px 0px 50px !important;
}

.card-step-section .text {
  max-width: 100%;
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.41px;
  color: #000000;
  width: 261px;
  margin-left: 0;
  margin-top: 40px;
  font-family: 'Noto Sans SemBd';
  font-style: normal;
  width: 100%;
  margin: 0px 0px 31px;
}

.card-step-section .icons-wrapper {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 31px;
}

.card-step-section .select-options {
  width: 100%;
  margin: 0;
}

.select-options .MuiCheckbox-root {
  width: auto;
  padding: 0;
  border-radius: 50%;
}

.select-options .MuiCheckbox-root svg.MuiSvgIcon-root {
  margin: 0 !important;
  opacity: 0;
}

.select-options .MuiCheckbox-root.Mui-checked {
  background: transparent;
}

.select-options .MuiCheckbox-root.Mui-checked:after {
  content: "";
  width: 60%;
  height: 60%;
  background: #000;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.select-options .MuiCheckbox-root {
  border: 1px solid black;
  width: 20px;
  height: 20px;
}

.select-options label.MuiFormControlLabel-root {
  margin-top: 0px !important;
  margin-left: 0 !important;
  margin-bottom: 41px !important;
  width: 100%;
  justify-content: start;
}

.select-options label.MuiFormControlLabel-root:last-child {
  margin-bottom: 0px !important;
}

.select-options span.MuiTypography-root {
  width: auto !important;
  margin-left: 40px !important;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.41px;
}

.card-step-section .question {
  width: 100%;
  margin: auto;
  margin-top: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.41px;
  color: #000000;
  margin-bottom: 41px;
  font-family: 'Noto Sans SemBd';
}

.submit-answer-wrapper button {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  max-width: 306px;
  width: 100%;
  height: auto;
  min-height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 63px;
}

.submit-answer-wrapper button:hover {
  background: #FFFFFF;
}

.submit-answer-wrapper .submit {
  letter-spacing: -0.41px;
  font-size: 24px;
  color: #000;
  text-transform: capitalize;
}

.select-options.select-radio span.MuiRadio-root svg.MuiSvgIcon-root {
  opacity: 0;
  display: none;
}

.select-options.select-radio span.MuiRadio-root {
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  padding: 0;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
}

.select-options.select-radio span.MuiRadio-root.Mui-checked {
  background-color: transparent;
}

.select-options.select-radio span.MuiRadio-root.Mui-checked :after {
  content: "";
  width: 60%;
  height: 60%;
  background: #000;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icons-wrapper .iconimage {
  margin: 0;
  margin-right: 30px;
  max-width: 33px;
}

.icons-wrapper .iconimage:last-child {
  margin-right: 0px !important;
}

.selected-active-buttons {
  width: auto;
  margin: 0;
}

.dropdown-select-menu .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.classSelect {
  width: 100% !important;
}

.dropdown-select-menu .MuiFormControl-root {
  width: 100% !important;
  margin: 0px !important;
}

.dropdown-select-menu {
  width: 100% !important;
  max-width: 280px !important;
  margin: 0 auto !important;
  margin-bottom: 51px !important;
}

.dropdown-select-menu .MuiSelect-select {
  min-height: 36px !important;
  padding: 0;
  border: 1px solid #000;
  padding-right: 38px !important;
}

.dropdown-select-menu .MuiSelect-select .notranslate {
  position: absolute;
  padding: 0px;
  padding-inline: 10PX;
  padding-top: 6px;
}

.dropdown-select-menu .MuiFormControl-root .notranslate {
  position: absolute;
  padding: 0px;
  padding-inline: 0;
  padding-top: 0;
  font-size: 24px;
  font-weight: 600;
  left: 15px;
}

.dropdown-select-menu .MuiOutlinedInput-notchedOutline legend.css-hdw1oc {
  display: none;
}

.dropdown-select-menu .MuiOutlinedInput-notchedOutline {
  top: 0px;
  border: none;
}

.dropdown-select-menu .MuiSelect-nativeInput {
  width: 100% !important;
  height: 100%;
  line-height: 1 !important;
}

.drop-down-cards {
  width: auto;
  margin: 0 !important;
  margin: 0px -15px !important;
  margin-bottom: 0px !important;
}

.drop-down-cards button.MuiButton-root {
  width: calc(50% - 30px);
  margin: 0px 15px;
  line-height: 1.5;
  min-height: 42px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  background: #fff;
  color: #000;
  letter-spacing: -0.41px;
  font-size: 18px;
  text-transform: capitalize;
  margin-bottom: 30px;
}

.drop-down-cards button.MuiButton-root.disabled {
  background: rgb(0 0 0 / 60%);
  color: #fff;
  text-decoration: underline;
}

ul.MuiList-root.MuiList-padding.MuiMenu-list {
  padding: 0;
}

.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  border-bottom: 2px solid transparent !important;
  position: relative;
  font-weight: 600;
  padding: 12px 0px;
  padding-left: 60px;
  width: 100%;
}

.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:after {
  content: "";
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 50%;
}

.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiButtonBase-root.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:before {
  width: 10.8px;
  height: 10.8px;
  background: black;
  position: absolute;
  left: 19.5px;
  top: 50%;
  content: "";
  border-radius: 50%;
  transform: translateY(-50%);
}

.dropdown-select-menu.dropdown-select-menu-second .MuiSelect-select {
  min-height: 55px !important;
}

.dropdown-select-menu.dropdown-select-menu-second .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-root.MuiFormLabel-colorPrimary.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -9px !important;
  font-size: 30px;
  font-weight: 600;
  color: #000;
  transform-origin: unset;
  max-width: unset;
  transition: unset;
  line-height: unset;
}

.dropdown-select-menu.dropdown-select-menu-second .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  transform: unset !important;
  font-size: 30px;
  font-weight: 600;
  color: #000 !important;
  left: 15px;
  top: 7px;
  transform-origin: unset;
  max-width: unset;
  transition: unset;
  line-height: unset;
}

.dropdown-select-menu svg.MuiSvgIcon-root {
  height: 38px;
  width: 38px;
  top: 0;
  right: 0;
  border: 1px solid #000;
}

.dropdown-select-menu .big-arrow {
  height: 38px;
  width: 38px;
  top: 0;
  right: 0;
  border: 1px solid #000;
  position: absolute;
}

.ticket-select-dropdown.big .big-arrow {
  height: 55px;
  width: 55px;
  top: 0;
  right: 0;
  border: 1px solid #000;
  position: absolute;
}

.ticket-select-dropdown.big .MuiOutlinedInput-root {
  height: 55px !important;
  max-height: 55px !important;
  padding-right: 60px !important;
}

.ticket-select-dropdown.big .MuiInputLabel-root {
  font-size: 30px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1) !important;
  top: -8px;
  z-index: 1;
}

.ticket-select-dropdown.big .MuiOutlinedInput-input {
  padding: 0px 12px !important;
  min-height: 38px;
  z-index: 11;
  font-size: 30px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1) !important;
}
.border-dropdown{
  border: 1px solid rgb(15, 15, 15)!important;
}
label#nba\ teams-label {
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.53) !important;
  top: 4px;
  transform: unset;
  transform-origin: unset;
  left: 12px;
}

.dropdown-select-menu svg.MuiSvgIcon-root path {
  display: none;
}

.dropdown-select-menu.dropdown-select-menu-second svg.MuiSvgIcon-root {
  height: 57px;
  width: 57px;
}

.dropdown-select-menu.dropdown-select-menu-second .big-arrow {
  height: 57px;
  width: 57px;
  position: absolute;
  right: 0;
  top: 0;
  display: none;
}

.dropdown-select-menu.dropdown-select-menu-second svg.MuiSvgIcon-root path {
  display: block;
  width: 100%;
  height: 100%;
  color: #C4C4C4;
}

.dropdown-select-menu.dropdown-select-menu-second .MuiSelect-select {
  padding-right: 55px !important;
}

.dropdown-tickets .MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-filledDefault.m-2 {
  font-size: 14px;
  letter-spacing: -0.41px;
  font-weight: 600;
  padding-left: 15px;
  padding-right: 30px;
  border-radius: 6px;
  background: #C4C4C4;
  margin: 11px;
}

.dropdown-tickets span.MuiChip-label {
  padding-left: 0px;
  padding-right: 0px;
}

.dropdown-tickets .MuiSvgIcon-root {
  position: absolute;
  width: 15px !important;
  height: 15px !important;
  top: 50% !important;
  transform: translateY(-50%);
  right: 0 !important;
  border: none !important;
}

.dropdown-tickets .MuiSvgIcon-root path {
  display: inline-block !important;
  color: #000000 !important;
}

.dropdown-tickets {
  margin: 0px -7px;
  margin-top: 14px;
}

.dropdown-tickets.dropdown-tickets-region {
  margin-top: 0px;
}

.logo {
  margin: auto;
  width: 100% !important;
  margin-top: 0px !important;
  padding: 20px 15px;
  max-width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo .header-buttons {
  margin: 0px;
}

.border-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  min-height: 0px !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: rgba(0, 0, 0, 0.10) !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover {
  background-color: rgba(0, 0, 0, 0.10) !important;
}

.loginpage {
  width: 100% !important;
  max-width: 500px !important;
  padding: 0px 15px;
  min-height: calc(100vh - 91px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}

.loginpage .input {
  width: 100%;
}

.ticket-select-dropdown {
  max-width: 280px;
  margin: 0 auto;
  margin-bottom: 43px;
}

.ticket-select-dropdown .MuiAutocomplete-root {
  width: 100% !important;
}

.ticket-select-dropdown .MuiAutocomplete-root.Mui-focused .MuiInputLabel-root {
  opacity: 0 !important;
}

.ticket-select-dropdown .MuiFormControl-root {
  width: 100%;
}

.ticket-select-dropdown .MuiChip-root.MuiAutocomplete-tag {
  display: none;
}

.ticket-select-dropdown button.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator.MuiAutocomplete-clearIndicatorDirty {
  display: none;
}

.close-btn {
  background: white;
  position: absolute;
  margin-left: -70px;
  margin-top: 5px;
  z-index: 13;
  border: none;
}

.ticket-select-dropdown .MuiOutlinedInput-root {
  max-height: 38px;
  padding: 0px;
  padding: 0px !important;
  border: 1px solid #000;
  padding-right: 40px !important;
}

.ticket-select-dropdown .MuiInputLabel-root {
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.53) !important;
  top: -11px;
  z-index: 1;
}

.ticket-select-dropdown .MuiOutlinedInput-input {
  padding: 0px 12px !important;
  min-height: 38px;
  z-index: 11;
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.53) !important;
}

.ticket-select-dropdown .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.53) !important;
  top: 4px;
  transform: unset;
  transform-origin: unset;
  left: 12px;
}

.ticket-select-dropdown .Mui-focused.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  opacity: 0 !important;
}

.ticket-select-dropdown .MuiOutlinedInput-notchedOutline {
  opacity: 0;
}

.ticket-select-dropdown .MuiOutlinedInput-notchedOutline .css-1in441m {
  display: none;
}

.ticket-select-dropdown .MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator {
  display: none;
}

.ticket-select-dropdown .big-arrow {
  height: 38px;
  width: 38px;
  top: 0;
  right: 0;
  border: 1px solid #000;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.result-top-btn {
  width: 100%;
  margin: auto;
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 50px;
}

.loginpage .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 12px !important;
}

.loginpage .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -4px;
}

p.MuiTypography-root.MuiListItemText-secondary.MuiTypography-body2.MuiTypography-colorTextSecondary.MuiTypography-displayBlock {
  margin-top: 15px !important;
}

.hover-text {
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.53) !important;
  top: -11px;
  z-index: 1;
  position: absolute;
  left: 13px;
  top: 4px;
}

.loginpage h6.MuiTypography-root {
  margin-top: 1.5rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.loginpage button.MuiButton-root.MuiButton-contained {
  margin-bottom: 0px;
}

.tabs {
  margin-right: 15px !important;
}

.tabs .MuiTabs-scroller {
  overflow: auto !important;
}

.tabs .MuiTabs-scroller::-webkit-scrollbar-track {
  opacity: 0 !important;
}

.tabs .MuiTabs-scroller::-webkit-scrollbar {
  opacity: 0 !important;
}

.tabs .MuiTabs-scroller::-webkit-scrollbar-thumb {
  opacity: 0 !important;
}

.loader.custom-loader {
  position: absolute !important;
  width: 100px !important;
  margin: 0 !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader.small-custom-loader {
  left: 30% !important;
  margin-top: 0px !important;
}

.loader.custom-loader span.MuiCircularProgress-root {
  width: 100% !important;
  width: 100% !important;
}

.logo img {
  max-width: 50px;
}

.ticket-select-dropdown .MuiAutocomplete-popper {
  top: 100% !important
}

.notranslate.hover-text {
  background-color: white !important;
  /* z-index: -1 !important; */
}

@media screen and (max-width: 1400px) {
  .loginpage {
    max-width: 500px !important;
  }
}

@media screen and (max-width: 1199px) {
  .logo {
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .card-step-section {
    max-width: 390px;
    width: 90%;
  }

  .logo {
    max-width: 100%;
  }

  .titlelogo {
    padding-bottom: 30px;
  }

  .loginpage {
    max-width: 400px !important;
  }

  .tabs .tab-name {
    width: auto;
  }
}

@media screen and (max-width: 389px) {
  .drop-down-cards button.MuiButton-root {
    width: calc(50% - 20px);
    margin: 0px 10px;
    margin-bottom: 20px;
  }

  .icons-wrapper .iconimage {
    margin-right: 20px;
    max-width: 25px;
  }

  .dropdown-select-menu {
    max-width: 100% !important;
  }

  .card-step-wrapper {
    padding: 30px 20px;
    margin: 30px 0px 30px !important;
  }

  .drop-down-cards {
    margin: 0 !important;
    margin: 0px -10px !important;
    margin-bottom: 0px !important;
  }

  .loginpage {
    max-width: 320px !important;
  }

}

button.back-button {
  margin-left: 630px !important;
  padding: 3px !important;

}

.k-multiselect.k-input.k-input-md.k-rounded-md.k-input-solid.k-valid.k-focus {
  overflow: hidden;

}

.pricemodel {
  margin-block: 10px;
  color: #000000 !important
}

/* .css-bdhsul-MuiTypography-root-MuiDialogTitle-root{
  padding:30px 0px !important
} */

.subcard-title {
  font-size: 23px !important;
  text-align: center;
}
.allrange{
  color:#000 !important;
}

.main-dailog {
  padding: 0px 20px;
  overflow-y: auto !important;
}

.slidercolor{
  color: #950f0f !important;
}
.pop-title {
  color: black !important;
  font-size: 28px;
}

.k-animation-container.k-animation-container-relative.k-animation-container-shown .k-popup {
  height: calc(100vh - 100px);
  overflow: auto;
  max-height: 400px;
}

.button.back-button.mx-2 {
  transform: translateX(calc(-50% + -265px));
  position: absolute;
  left: 50%;
  top: 0;
  margin: 0 !important;
}

.ticket-select-dropdown {
  max-width: 300px !important;
}

@media (max-width:767px) {

  .button.back-button {
    transform: translateX(calc(-50% + -162px)) !important;
    position: absolute !important;
    top: -42px !important;

  }
}

@media (max-width:430px) {

  .button.back-button {
    transform: unset !important;
    position: absolute !important;
    top: -42px !important;
    left: 20px !important;

  }
}

@media (max-width:389px) {
  .button.back-button{
    transform: unset !important;
    position: relative !important;
    top: 15px !important;
    left: 20px !important;
  }
}

@media (max-width:360px) {
  .k-multiselect.k-input.k-input-md.k-rounded-md.k-input-solid.k-valid {
    width: 280px !important
  }

  .ticket-select-dropdown {
    max-width: 280px !important
  }
}

@media (max-width:280px) {
  .k-multiselect.k-input.k-input-md.k-rounded-md.k-input-solid.k-valid {
    width: 212px !important
  }

  ;

  .ticket-select-dropdown {
    max-width: 280px !important
  }
}

@media (max-width:393px) {
  .k-multiselect.k-input.k-input-md.k-rounded-md.k-input-solid.k-valid {
    width: 282px !important
  }

  ;

  .ticket-select-dropdown {
    max-width: 280px !important
  }
}

@media (max-width:375px) {
  .k-multiselect.k-input.k-input-md.k-rounded-md.k-input-solid.k-valid {
    width: 295px !important
  }

  ;

  .ticket-select-dropdown {
    max-width: 280px !important
  }
}

.img-wrapper {
  width: 300px;
  padding: 0px 20px;
  /* overflow: hidden !important; */
}

.img-wrapper img {
  max-width: 100%;
  width: auto;
  height: auto;
}

.popup-body {
  padding: 0px 20px !important;
  width: calc(100% - 300px);
}

.k-input-values.k-chip-list.k-selection-multiple.k-chip-list-md{
  display: none !important;
}
.k-icon.k-i-x{
  display: none !important;
}

.box .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
  width: 100% !important;
  max-width: 900px !important;
  min-height: auto !important;
  min-width: auto !important;
  height: auto !important;
  max-height: 90vh !important;
  padding-bottom: 30px;
  margin: 20px !important;
}
.k-floating-label-container > .k-label, .k-floating-label-container.k-state-focused > .k-label, .k-floating-label-container.k-focus > .k-label {
  top: 24px !important;
  left: 14px !important;
  transform: scale(0);
  font-size: 18px !important;
}

.k-icon.k-i-x{
  margin-left:-20px !important
}
.dropdown-tickets{
  overflow-x: hidden;
  max-height: 400px;
}
p.MuiDialogContentText-root.MuiTypography-root.MuiTypography-body1.full-dailog {
  display: flex;
  flex-wrap: wrap;
}
.css-iqd1hh-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon{
  margin: 0 10px 0px 0px !important;
}
.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-deleteIcon.MuiChip-deleteIconMedium.MuiChip-deleteIconColorDefault.MuiChip-deleteIconOutlinedColorDefault{
  margin-left:30px ;
}
.winerange{
  display:flex;
}
.winerangetwo{
  margin-left:1.5rem !important;
  margin-right:1.5rem !important;
}
@media (max-width:991px) {
  .popup-body {
    width: 100% !important;
  }
  .main-dailog{
    overflow-y: auto !important;
  }
}
@media (max-width:375px) {
  .winerange {
   display: grid;
  }
  .winerangetwo{
   margin-left:0px !important;
   margin-right:0px !important
  }
  .main-dailog{
    overflow-y: auto !important;
  }
}
@media (max-width:549px) {
  .winerange {
   display: grid;
  }
  .winerangetwo{
   margin-left:0px !important;
   margin-right:0px !important
  }
  .main-dailog{
    overflow-y: auto !important;
  }
  
  
}
.k-checkbox {
  border: 1px solid black !important;
}

.k-searchbox {
  border: 1px solid black !important;
}