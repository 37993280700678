.text{
font-weight: 600 ;
font-size: 30px ;
line-height: 30px ;
text-align: center;
letter-spacing: -0.408px ;
color: #000000;
width: 261px;
   margin-left: 58px;
   margin-top: 40px;
    font-family: 'Noto Sans';
font-style: normal;
}